// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-blogs-jsx": () => import("./../../../src/pages/landing/blogs.jsx" /* webpackChunkName: "component---src-pages-landing-blogs-jsx" */),
  "component---src-pages-landing-featured-jsx": () => import("./../../../src/pages/landing/featured.jsx" /* webpackChunkName: "component---src-pages-landing-featured-jsx" */),
  "component---src-pages-landing-grid-row-jsx": () => import("./../../../src/pages/landing/GridRow.jsx" /* webpackChunkName: "component---src-pages-landing-grid-row-jsx" */),
  "component---src-pages-landing-recent-jsx": () => import("./../../../src/pages/landing/recent.jsx" /* webpackChunkName: "component---src-pages-landing-recent-jsx" */),
  "component---src-pages-landing-recent-list-jsx": () => import("./../../../src/pages/landing/recentList.jsx" /* webpackChunkName: "component---src-pages-landing-recent-list-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/post-template.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */)
}

